<template>
  <el-dialog
    :modal="false"
    title="车存盘点单"
    :visible.sync="showDialog"
    width="1300px"
    top="5vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        inline
        style="display: flex; justify-content: space-between"
      >
        <div>
          <el-form-item label="盘点车辆:">
            <el-input v-model="form.depot_name" disabled></el-input>
          </el-form-item>
          <el-form-item label="盘点人:">
            <el-input v-model="form.check_name" disabled></el-input>
          </el-form-item>
          <el-form-item label="盘点单号:">
            <el-input v-model="form.code" disabled></el-input>
          </el-form-item>
          <el-form-item label="盘点时间:">
            <el-input v-model="form.create_at" disabled></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item>
            <class-select
              ref="classSelect"
              style="width: 140px; margin-right: 20px"
              @class-select-change="chooseClass"
            ></class-select>
          </el-form-item>
          <el-form-item>
            <brand-select
              ref="brandSelect"
              style="width: 140px"
              @brand-select-change="chooseBrand"
            ></brand-select>
          </el-form-item>
          <el-form-item>
            <goods-search
              ref="goodsSearch"
              :is-table="false"
              @select-goods="selectGoodsId"
            ></goods-search>
          </el-form-item>
          <!-- <el-form-item>
            <el-button @click="handleQuery">查询</el-button>
          </el-form-item> -->
        </div>
      </el-form>
      <el-table v-loading="loading" stripe :data="pageList" height="400px">
        <!-- 序号 -->
        <el-table-column align="center" type="index" width="50">
          <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in columns"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <!-- 选择框 -->
        <!-- <el-table-column
          align='center'
          type='selection'
          width='50'
        ></el-table-column> -->
        <el-table-column
          v-for="(item, tableIndex) in finallyColumns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          width=""
          align="center"
        >
          <template v-if="item.prop == 'goods_name'" #default="{ row, $index }">
            <div v-if="row.isAdd">
              <goods-search
                ref="goodsSearchTable"
                :f-key="row.goods_name"
                @add-rows="addRows($event, $index)"
                @select-goods="selectGoodsIdT($event, row)"
                @select-goods-all="selectGoodsT($event, row)"
              ></goods-search>
            </div>
            <div v-else>
              {{ row[item.prop] }}
            </div>
          </template>
          <template v-else-if="item.prop == 'check_num'" #default="{ row }">
            <el-form inline>
              <el-form-item
                v-for="(unitItem, unitIndex) in row.unit_list"
                :key="unitIndex"
              >
                <el-input
                  v-model="unitItem.num"
                  style="width: 60px"
                  onkeyup="value=value.replace(/[^\-?\d.]/g,'')"
                  @input="updateRowDelay($event, row, unitIndex)"
                  @focus="handleFocus($event, unitItem)"
                  @blur="handleBlur($event, unitItem)"
                ></el-input>
                {{ unitItem.unit_name }}
              </el-form-item>
            </el-form>
          </template>
          <!-- <template v-else-if="item.prop == 'diff_num'" #default="{ row }">
            {{ ykNum(row) }}
          </template> -->
          <template v-else #default="{ row }">
            {{ row[item.prop] }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="" align="center" label="操作" min-width="80px">
          <template #default="{ $index, row }">
            <el-button
              type="text"
              @click.native.prevent="handleDeleteRow($index, row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        :current-page="form.pageNo"
        :layout="layout"
        :page-size="form.pageSize"
        :total="total_"
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>

    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <!-- <el-button type="warning">未填写盘点数量的填入当前库存量</el-button> -->

      <el-button type="primary" @click="handleSave(null)">保存</el-button>
      <el-button @click="handleExport">导出</el-button>

      <el-button @click="handlePrint">打印</el-button>
      <el-button type="primary" @click="handleSubmit">提交盘点</el-button>
    </div>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="PD"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import { getAllSelect } from '@/api/advanceOrder'
  import { jbrSelect } from '@/api/purchase'
  import {
    getDetail,
    startCheck,
    saveCheck,
    completeList,
  } from '@/api/depotCheck'
  import BrandSelect from '@/baseComponents/brandSelect'
  import ClassSelect from '@/baseComponents/classSelect'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import { downloadFile } from '@/api/Employee'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import { postAction } from '@/api/Employee'

  export default {
    name: 'Continue',
    components: {
      BrandSelect,
      ClassSelect,
      GoodsSearch,
      RuilangDisplayDialog,
    },
    data() {
      return {
        loading: false,
        showDialog: false,
        depotList: [],
        checkPeopleList: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        data_id: null,
        form: {
          time: '',
          check_user_id: '',
          depot_id: '',
          check_id: '', //盘点id
          goods_name: '', //商品名称
          goods_id: '',
          brand_id: '', //品牌id
          class_id: '', //分类别id
          pageNo: 1, //页数
          pageSize: 10, //条数,默认10条
        },
        pageNo: 1, //页数
        pageSize: 10, //条数,默认10条
        checkList: [
          '商品名称',
          '规格',
          '单位换算',
          '当前库存量',
          '盘点数量',
          '盈亏数量',
        ],
        columns: [
          {
            order: 1,
            prop: 'goods_name',
            label: '商品名称',
            width: '220px',
          },
          {
            order: 2,
            prop: 'specs',
            label: '规格',
            width: '',
          },
          // {
          //   order: 3,
          //   prop: 'unit_code',
          //   label: '条码',
          //   width: '',
          // },
          {
            order: 4,
            prop: 'goods_convert',
            label: '单位换算',
            width: '',
          },
          {
            order: 6,
            prop: 'stock_num',
            label: '当前库存量',
            width: '',
          },
          {
            order: 7,
            prop: 'check_num',
            label: '盘点数量',
            width: '340',
          },
          {
            order: 8,
            prop: 'diff_num',
            label: '盈亏数量',
            width: '',
          },
          // {
          //   order: 9,
          //   prop: 'sell_money',
          //   label: '盈亏货值',
          //   width: '',
          // },
          // {
          //   order: 10,
          //   prop: 'balance_money',
          //   label: '盈亏成本',
          //   width: '',
          // },
        ],
        list: [],
      }
    },
    computed: {
      total_() {
        return this.total && this.total - 1
      },
      pageList() {
        // 处理筛选
        // this.form.pageNo = 1
        let filterArr = {}
        if (this.form.class_id) {
          filterArr.class_id = this.form.class_id
        }
        if (this.form.brand_id) {
          filterArr.brand_id = this.form.brand_id
        }
        if (this.form.goods_id) {
          filterArr.goods_id = this.form.goods_id
        }
        let a = this.filterF(filterArr, this.list).length
        this.total = a
        return this.filterF(filterArr, this.list).slice(
          (this.form.pageNo - 1) * this.form.pageSize,
          this.form.pageNo * this.form.pageSize
        )
      },
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.form.pageNo = 1
          this.form.pageSize = 10
        }
      },
    },
    created() {
      // 仓库下拉
      getAllSelect().then((res) => {
        console.log(res)
        this.depotList = res.data.depot
      })
      // 盘点人下拉
      jbrSelect().then((res) => {
        this.checkPeopleList = res.data.rows
      })
    },
    mounted() {},
    methods: {
      // 处理表格盈亏数量
      ykNum(row) {
        console.log(row.isAdd)
        if (row.isAdd || row.edit) {
          let s = ''
          row.unit_list.forEach((item) => {
            s += item.num + item.unit_name + ''
          })
          return s
        } else {
          return row.diff_num
        }
      },
      async fetchData() {
        this.loading = true
        let { data, msg, code, totalCount } = await getDetail({
          ...this.form,
          ...{
            pageSize: -1,
          },
        })
        console.log(CharacterData)
        this.list = data.goods_list
        this.total = totalCount
        this.form = { ...this.form, ...data }
        this.form.check_user_id = Number(data.check_id)
        this.form.depot_id = data.depot_id
        this.form.time = data.create_at
        this.addRow()
        this.loading = false
      },
      selectGoodsId(val) {
        if (val) {
          this.form.goods_id = val
        } else {
          this.form.goods_id = ''
        }
      },
      chooseClass(val) {
        this.form.class_id = val
      },
      chooseBrand(val) {
        this.form.brand_id = val
      },
      handleSubmit() {
        console.log('提交盘点')
        this.handleSave(() => {
          completeList(this.form).then((res) => {
            console.log(res)
            this.$message.success(res.msg)
            this.$emit('refresh')
            this.close()
          })
        })
      },
      handleSave(cb) {
        let goods_data = []
        this.list.forEach((item) => {
          if (item.goods_name !== '') {
            goods_data.push({
              goods_id: item.goods_id,
              unit_data: item.unit_list,
            })
          }
        })
        console.log(goods_data)
        saveCheck({
          check_id: this.form.id,
          goods_data: JSON.stringify(goods_data),
        }).then((res) => {
          console.log(res)
          if (cb) {
            cb()
          } else {
            this.$message.success(res.msg)
          }
        })
      },
      // input 节流
      updateRowDelay(val, row, index) {
        if (val.indexOf('-') >= 0) {
          console.log('输入负数了', row)
          row.unit_list[index].num = ''
        } else {
          console.log('节流')
          if (this.timer) {
            this.timer = null
          } else {
            this.timer = setTimeout(() => {
              this.updateRow(val, row)
              this.timer = null
            }, 200)
          }
        }
      },
      handleFocus(v, n) {
        if (n.num == '--') {
          n.num = ''
        }
      },
      handleBlur(v, n) {
        if (n.num == '') {
          n.num = '--'
        }
      },
      updateRow(val, row) {
        row.unit_list.forEach((i) => {
          if (i.num == '--') {
            i.num = ''
          }
        })
        postAction('/depotAdmin/check/num', {
          check_id: this.form.id,
          goods_id: row.goods_id,
          unit_data: JSON.stringify(row.unit_list),
        }).then((r) => {
          this.$set(row, 'diff_num', r.data)
        })
      },
      // handleStart() {
      //   startCheck(this.form).then((res) => {
      //     console.log(res)
      //     this.$message.success(res.msg)
      //   })
      // },
      // 筛选function
      filterF(condition, data) {
        return data.filter((item) => {
          return Object.keys(condition).every((key) => {
            return String(item[key])
              .toLowerCase()
              .includes(String(condition[key]).trim().toLowerCase())
          })
        })
      },
      handleQuery() {
        this.form.pageSize = 10
        this.form.pageNo = 1
        this.fetchData()
      },
      close() {
        this.showDialog = false
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        // this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        // this.fetchData()
      },
      handleAdd(index, row) {},
      handleDeleteRow(index, row) {
        if (this.list.length == 1) {
          this.$message.error('不能删除最后一行')
        } else if (
          row.goods_name == '' &&
          this.list.filter((item) => item.goods_name == '').length == 1
        ) {
          this.$message.error('不能删除此行')
        } else {
          this.list.splice(index, 1)
        }
      },
      addRow() {
        this.list.push({
          goods_name: '',
          isAdd: true,

          goods_id: '',
          goods_name: '',
          specs: '',
          goods_convert: '',
          unit_code: '',
          unit_list: [],
          check_num: '',
          stock_num: '',
          diff_num: '',
          balance_money: '',
          sell_money: '',
        })
      },
      addRows(val, index) {
        console.log(val)
        let addData = JSON.parse(JSON.stringify(val))

        addData.forEach((item) => {
          item.unit_list = JSON.parse(JSON.stringify(item.arr_unit))
          item.unit_list.forEach((i) => {
            i.num = 0
          })
          item.isAdd = true
          item.goods_convert = item.unit_cv
          item.stock_num = item.balance_count
          item.diff_num = this.ykNum(item)
          // this.list.splice(index, 0, item)
          this.list.splice(this.list.length - 1, 0, item)
        })
      },
      clearForm() {
        this.$refs.form.resetFields()
        this.$refs.classSelect.resetForm()
        this.$refs.brandSelect.resetForm()
        this.$refs.goodsSearch.resetForm()
      },
      selectGoodsIdT(val, row) {
        row.goods_id = val
      },
      selectGoodsT(val, row) {
        val.arr_unit.forEach((item) => {
          item.unit_id = item.id
          item.num = 0
        })
        let newData = JSON.parse(JSON.stringify(val))
        // 计算盈亏数量
        // let ykNum =
        console.log(val, row)
        row.goods_name = val.goods_name
        row.goods_id = val.goods_id
        row.specs = val.specs
        row.unit_list = newData.arr_unit
        row.goods_convert = val.unit_cv
        row.stock_num = val.balance_count
        // row.diff_num = ykNum
        // 添加row
        console.log(this.list[this.list.length - 1].goods_name)
        if (this.list[this.list.length - 1].goods_name !== '') {
          console.log('1')
          this.addRow()
          console.log('2')
        }
      },
      handleExport() {
        downloadFile('/depotAdmin/check/down', '车存盘点.xlsx', {
          check_id: this.form.id,
        })
      },
      handlePrint() {
        this.data_id = this.form.check_id
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },
    },
  }
</script>
<style lang="scss" scoped></style>
