<template>
  <el-dialog
    :modal="false"
    title="车存盘点单"
    :visible.sync="showDialog"
    width="1300px"
    top="5vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container" style="position: relative">
      <div class="status">{{ status }}</div>
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="90px"
      >
        <div>
          <el-form-item label="盘点车辆:">
            <el-input v-model="form.depot_name" disabled></el-input>
          </el-form-item>
          <el-form-item label="盘点人:">
            <el-input v-model="form.check_name" disabled></el-input>
          </el-form-item>
          <el-form-item label="审核人:">
            <el-input v-model="form.check_name" disabled></el-input>
          </el-form-item>
          <div></div>
          <el-form-item label="盘点单号:">
            <el-input v-model="form.code" disabled></el-input>
          </el-form-item>
          <el-form-item label="盘点时间:">
            <el-input v-model="form.create_at" disabled></el-input>
          </el-form-item>
          <el-form-item label="审核时间:">
            <el-input v-model="form.create_at" disabled></el-input>
          </el-form-item>
          <el-form-item label="" prop="">
            <el-radio v-model="type" label="1">价格=销售价</el-radio>
            <el-radio v-model="type" label="2">价格=成本价</el-radio>
          </el-form-item>
        </div>
      </el-form>
      <el-table v-loading="loading" stripe :data="list" max-height="400px">
        <!-- 序号 -->
        <el-table-column align="center" type="index" width="50">
          <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in columns"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, tableIndex) in finallyColumns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          width=""
          align="center"
        ></el-table-column>
      </el-table>
      <div style="display: flex; align-items: center; margin-top: 15px">
        <el-pagination
          :current-page="form.pageNo"
          :layout="layout"
          :page-size="form.pageSize"
          :total="total"
          style="margin: 0; flex: 1"
          background
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
        <div v-if="type == 1" style="width: 150px">
          盈亏合计:{{ form.sell_money }}
        </div>
        <div v-else-if="type == 2" style="width: 150px">
          盈亏合计:{{ form.balance_money }}
        </div>
      </div>
    </div>

    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button @click="handleExport">导出</el-button>

      <el-button @click="handlePrint">打印</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="PD"
      :data_id="form.check_id"
      :type="1"
      :price_type="type"
    ></ruilang-display-dialog>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import { getDetail, startCheck, saveCheck } from '@/api/depotCheck'
  import { downloadFile } from '@/api/Employee'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  export default {
    name: 'Check',
    components: { RuilangDisplayDialog },
    data() {
      return {
        type: '1',
        loading: false,
        status: '状态',
        showDialog: false,
        depotList: [],
        data_id: null,
        checkPeopleList: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        bill_list: {
          bill_code: '',
        },
        form: {
          time: '',
          check_user_id: '',
          depot_name: '',
          check_name: '',
          create_at: '',
          depot_id: '',
          check_id: '', //盘点id
          goods_name: '', //商品名称
          goods_id: '',
          brand_id: '', //品牌id
          class_id: '', //分类别id
          pageNo: 1, //页数
          pageSize: 10, //条数,默认10条
        },
        checkList: [
          '商品名称',
          '规格',
          '单位换算',
          '当前库存量',
          '盘点数量',
          '盈亏数量',
          '价格',
          '金额',
        ],
        columns: [
          {
            order: 1,
            prop: 'goods_name',
            label: '商品名称',
            width: '220px',
          },
          {
            order: 2,
            prop: 'specs',
            label: '规格',
            width: '',
          },
          {
            order: 4,
            prop: 'goods_convert',
            label: '单位换算',
            width: '',
          },
          {
            order: 6,
            prop: 'stock_num',
            label: '当前库存量',
            width: '',
          },
          {
            order: 7,
            prop: 'check_num',
            label: '盘点数量',
            width: '340',
          },
          {
            order: 8,
            prop: 'diff_num',
            label: '盈亏数量',
            width: '',
          },
          {
            order: 9,
            prop: 'sell_price',
            label: '价格',
            width: '',
          },
          {
            order: 10,
            prop: 'sell_money',
            label: '金额',
            width: '',
          },
        ],
        list: [],
        summaryTotal: {},
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      type(v) {
        if (v == 1) {
          this.columns[6].prop = 'sell_price'
          this.columns[7].prop = 'sell_money'
          this.fetchData()
        } else {
          this.columns[6].prop = 'balance_price'
          this.columns[7].prop = 'balance_money'
          this.fetchData()
        }
      },
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.form.pageNo = 1
          this.form.pageSize = 10
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      // 处理表格盈亏数量
      ykNum(row) {
        console.log(row.isAdd)
        if (row.isAdd || row.edit) {
          let s = ''
          row.unit_list.forEach((item) => {
            s += item.num + item.unit_name + ''
          })
          return s
        } else {
          return row.diff_num
        }
      },
      async fetchData() {
        let { data, msg, code, totalCount } = await getDetail({
          ...this.form,
          ...{ price_type: this.type },
        })
        console.log(CharacterData)
        this.list = data.goods_list
        this.total = totalCount
        this.summaryTotal.jiage = data.sell_money
        this.summaryTotal.jine = data.balance_money
        this.form.check_user_id = Number(data.check_id)
        this.form.depot_name = data.depot_name
        this.form.code = data.code
        this.form.check_name = data.check_name
        this.form.create_at = data.create_at
        this.form.total_money = data.total_money
        this.form.sell_money = data.sell_money
        this.form.balance_money = data.balance_money
        // this.addRow()
      },
      selectGoodsId(val) {
        if (val) {
          this.form.goods_id = val
        } else {
          this.form.goods_id = ''
        }
      },
      chooseClass(val) {
        this.form.class_id = val
      },
      chooseBrand(val) {
        this.form.brand_id = val
      },
      handleSave() {
        let goods_data = []
        this.list.forEach((item) => {
          if (item.goods_name !== '') {
            goods_data.push({
              goods_id: item.goods_id,
              unit_data: item.unit_list,
            })
          }
        })
        console.log(goods_data)
        saveCheck({
          check_id: this.form.check_id,
          goods_data: JSON.stringify(goods_data),
        }).then((res) => {
          console.log(res)
          this.$message.success(res.msg)
        })
      },
      // handleStart() {
      //   startCheck(this.form).then((res) => {
      //     console.log(res)
      //     this.$message.success(res.msg)
      //   })
      // },
      handleQuery() {
        this.form.pageNo = 1
        this.fetchData()
      },
      close() {
        this.showDialog = false
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      handleAdd(index, row) {},
      handleDeleteRow(index, row) {
        if (this.list.length == 1) {
          this.$message.error('不能删除最后一行')
        } else if (
          row.goods_name == '' &&
          this.list.filter((item) => item.goods_name == '').length == 1
        ) {
          this.$message.error('不能删除此行')
        } else {
          this.list.splice(index, 1)
        }
      },
      addRow() {
        this.list.push({
          goods_name: '',
          isAdd: true,

          goods_id: '',
          goods_name: '',
          specs: '',
          goods_convert: '',
          unit_code: '',
          unit_list: [],
          check_num: '',
          stock_num: '',
          diff_num: '',
          balance_money: '',
          sell_money: '',
        })
      },
      addRows(val, index) {
        console.log(val)
        let addData = JSON.parse(JSON.stringify(val))

        addData.forEach((item) => {
          item.unit_list = JSON.parse(JSON.stringify(item.arr_unit))
          item.unit_list.forEach((i) => {
            i.num = 0
          })
          item.isAdd = true
          item.goods_convert = item.unit_cv
          item.stock_num = item.balance_count
          item.diff_num = this.ykNum(item)
          this.list.splice(index, 0, item)
        })
      },
      clearForm() {
        this.$refs.form.resetFields()
        this.$refs.classSelect.resetForm()
        this.$refs.brandSelect.resetForm()
        this.$refs.goodsSearch.resetForm()
      },
      selectGoodsIdT(val, row) {
        row.goods_id = val
      },
      selectGoodsT(val, row) {
        val.arr_unit.forEach((item) => {
          item.unit_id = item.id
          item.num = 0
        })
        let newData = JSON.parse(JSON.stringify(val))
        // 计算盈亏数量
        // let ykNum =
        console.log(val, row)
        row.goods_name = val.goods_name
        row.goods_id = val.goods_id
        row.specs = val.specs
        row.unit_list = newData.arr_unit
        row.goods_convert = val.unit_cv
        row.stock_num = val.balance_count
        // row.diff_num = ykNum
        // 添加row
        console.log(this.list[this.list.length - 1].goods_name)
        if (this.list[this.list.length - 1].goods_name !== '') {
          console.log('1')
          this.addRow()
          console.log('2')
        }
      },
      handleExport() {
        downloadFile('/depotAdmin/check/down', '车存盘点.xlsx', {
          check_id: this.form.check_id,
        })
      },
      handlePrint() {
        this.data_id = this.form.check_id
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },
      summaryMethod() {
        let sums = []
        sums[0] = '合计'
        if (this.type == 1) {
          sums[8] = this.summaryTotal.jiage
        } else {
          sums[8] = this.summaryTotal.jine
        }
        return sums
      },
    },
  }
</script>
<style lang="scss" scoped>
  .status {
    position: absolute;
    top: -40px;
    right: 80px;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 1px #f60 solid;
    border-radius: 50%;
  }
</style>
