var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "车存盘点单",
        visible: _vm.showDialog,
        width: "1300px",
        top: "5vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "orderTest-container",
          staticStyle: { position: "relative" },
        },
        [
          _c("div", { staticClass: "status" }, [_vm._v(_vm._s(_vm.status))]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "90px",
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "盘点车辆:" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.depot_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "depot_name", $$v)
                          },
                          expression: "form.depot_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "盘点人:" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.check_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "check_name", $$v)
                          },
                          expression: "form.check_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核人:" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.check_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "check_name", $$v)
                          },
                          expression: "form.check_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div"),
                  _c(
                    "el-form-item",
                    { attrs: { label: "盘点单号:" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "code", $$v)
                          },
                          expression: "form.code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "盘点时间:" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.create_at,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "create_at", $$v)
                          },
                          expression: "form.create_at",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核时间:" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.create_at,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "create_at", $$v)
                          },
                          expression: "form.create_at",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.type,
                            callback: function ($$v) {
                              _vm.type = $$v
                            },
                            expression: "type",
                          },
                        },
                        [_vm._v("价格=销售价")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.type,
                            callback: function ($$v) {
                              _vm.type = $$v
                            },
                            expression: "type",
                          },
                        },
                        [_vm._v("价格=成本价")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { stripe: "", data: _vm.list, "max-height": "400px" },
            },
            [
              _c(
                "el-table-column",
                { attrs: { align: "center", type: "index", width: "50" } },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            "popper-class": "custom-table-checkbox",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.checkList,
                                callback: function ($$v) {
                                  _vm.checkList = $$v
                                },
                                expression: "checkList",
                              },
                            },
                            _vm._l(_vm.columns, function (item, index) {
                              return _c("el-checkbox", {
                                key: index,
                                attrs: { label: item.label },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", type: "text" },
                              slot: "reference",
                            },
                            [
                              _c("vab-remix-icon", {
                                attrs: { icon: "settings-line" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._l(_vm.finallyColumns, function (item, tableIndex) {
                return _c("el-table-column", {
                  key: tableIndex,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    width: "",
                    align: "center",
                  },
                })
              }),
            ],
            2
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "margin-top": "15px",
              },
            },
            [
              _c("el-pagination", {
                staticStyle: { margin: "0", flex: "1" },
                attrs: {
                  "current-page": _vm.form.pageNo,
                  layout: _vm.layout,
                  "page-size": _vm.form.pageSize,
                  total: _vm.total,
                  background: "",
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
              _vm.type == 1
                ? _c("div", { staticStyle: { width: "150px" } }, [
                    _vm._v(" 盈亏合计:" + _vm._s(_vm.form.sell_money) + " "),
                  ])
                : _vm.type == 2
                ? _c("div", { staticStyle: { width: "150px" } }, [
                    _vm._v(" 盈亏合计:" + _vm._s(_vm.form.balance_money) + " "),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleExport } }, [
            _vm._v("导出"),
          ]),
          _c("el-button", { on: { click: _vm.handlePrint } }, [_vm._v("打印")]),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
      _c("ruilang-display-dialog", {
        ref: "RuilangDisplayDialog",
        attrs: {
          bill_type: "PD",
          data_id: _vm.form.check_id,
          type: 1,
          price_type: _vm.type,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }