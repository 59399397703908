var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "审核",
        visible: _vm.showDialog,
        width: "1300px",
        top: "5vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
              },
              attrs: { model: _vm.form, inline: "" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "盘点车辆:" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.depot_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "depot_name", $$v)
                      },
                      expression: "form.depot_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "盘点人:" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.check_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "check_name", $$v)
                      },
                      expression: "form.check_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "盘点单号:" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "code", $$v)
                      },
                      expression: "form.code",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "盘点时间:" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.create_at,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "create_at", $$v)
                      },
                      expression: "form.create_at",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { stripe: "", data: _vm.pageData, height: "400px" },
            },
            [
              _c(
                "el-table-column",
                { attrs: { align: "center", type: "index", width: "50" } },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            "popper-class": "custom-table-checkbox",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.checkList,
                                callback: function ($$v) {
                                  _vm.checkList = $$v
                                },
                                expression: "checkList",
                              },
                            },
                            _vm._l(_vm.columns, function (item, index) {
                              return _c("el-checkbox", {
                                key: index,
                                attrs: { label: item.label },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", type: "text" },
                              slot: "reference",
                            },
                            [
                              _c("vab-remix-icon", {
                                attrs: { icon: "settings-line" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._l(_vm.finallyColumns, function (item, tableIndex) {
                return _c("el-table-column", {
                  key: tableIndex,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    width: "",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      item.prop == "goods_name"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return [
                                row.isAdd
                                  ? _c(
                                      "div",
                                      [
                                        _c("goods-search", {
                                          ref: "goodsSearchTable",
                                          refInFor: true,
                                          attrs: { "f-key": row.goods_name },
                                          on: {
                                            "add-rows": function ($event) {
                                              return _vm.addRows($event, $index)
                                            },
                                            "select-goods": function ($event) {
                                              return _vm.selectGoodsIdT(
                                                $event,
                                                row
                                              )
                                            },
                                            "select-goods-all": function (
                                              $event
                                            ) {
                                              return _vm.selectGoodsT(
                                                $event,
                                                row
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c("div", [
                                      _vm._v(
                                        " " + _vm._s(row[item.prop]) + " "
                                      ),
                                    ]),
                              ]
                            },
                          }
                        : item.prop == "check_num"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-form",
                                  {
                                    attrs: {
                                      inline: "",
                                      disabled: _vm.form.status == 2,
                                    },
                                  },
                                  _vm._l(
                                    row.unit_list,
                                    function (unitItem, unitIndex) {
                                      return _c(
                                        "el-form-item",
                                        { key: unitIndex },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "60px" },
                                            attrs: {
                                              onkeyup:
                                                "value=value.replace(/[^\\-?\\d.]/g,'')",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.updateRowDelay(
                                                  $event,
                                                  row
                                                )
                                              },
                                            },
                                            model: {
                                              value: unitItem.num,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  unitItem,
                                                  "num",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression: "unitItem.num",
                                            },
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(unitItem.unit_name) +
                                              " "
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ]
                            },
                          }
                        : {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(" " + _vm._s(row[item.prop]) + " "),
                              ]
                            },
                          },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "",
                  align: "center",
                  label: "操作",
                  "min-width": "80px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleDeleteRow($index, row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.form.pageNo,
              layout: _vm.layout,
              "page-size": _vm.form.pageSize,
              total: _vm.total,
              background: "",
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.form.status != 2
            ? _c("el-button", { on: { click: _vm.handleSave } }, [
                _vm._v("保存"),
              ])
            : _vm._e(),
          _c("el-button", [_vm._v("导出")]),
          _c("el-button", { on: { click: _vm.handlePrint } }, [_vm._v("打印")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleReview } },
            [_vm._v(" 审核并生成盘盈盘亏单 ")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
      _c("ruilang-display-dialog", {
        ref: "RuilangDisplayDialog",
        attrs: { bill_type: "PD", data_id: _vm.data_id, type: 1 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }