<template>
  <!-- NAME[epic=销售] 车辆对货 -->
  <div class="orderTest-container">
    <el-form :model="form" inline>
      <el-form-item label="">
        <el-select
          v-model="form.depot_id"
          clearable
          style="width: 140px"
          placeholder="请选择车辆"
        >
          <el-option
            v-for="(i, idx) in carList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select
          v-model="form.check_id"
          clearable
          style="width: 140px"
          placeholder="请选择盘点人"
        >
          <el-option
            v-for="(i, idx) in staffList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select
          v-model="form.status_type"
          clearable
          style="width: 140px"
          placeholder="状态"
        >
          <el-option
            v-for="(i, idx) in statusList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleAdd">新增盘点</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      ></el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="160px"
      >
        <template #default="{ $index, row }">
          <el-button
            v-if="row.status == 1"
            type="text"
            @click.native.prevent="handleContinue($index, row)"
          >
            继续
          </el-button>
          <el-button
            v-if="row.status != 1"
            type="text"
            @click.native.prevent="handleView($index, row)"
          >
            查看
          </el-button>
          <el-button
            v-if="row.status == 2"
            type="text"
            @click.native.prevent="handleReview($index, row)"
          >
            审核
          </el-button>

          <el-button
            v-if="row.status != 4"
            type="text"
            @click.native.prevent="handleDelete($index, row)"
          >
            作废
          </el-button>
          <!-- <el-button
            v-if="row.status != 4"
            type="text"
            @click.native.prevent="handleprint($index, row)"
          >
            打印
          </el-button> -->
        </template>
      </el-table-column>
      <!-- <div slot="append">
        <div class="sum_footer xiaoji" ref="sum_xiaoji">
          <div class="sum_footer_unit center">小计</div>
          <div class="sum_footer_unit">123</div>
          <div class="sum_footer_unit">123</div>
          <div class="sum_footer_unit">123</div>
          <div class="sum_footer_unit">123</div>
          <div class="sum_footer_unit">123</div>
          <div class="sum_footer_unit">123</div>
          <div class="sum_footer_unit">123</div>
        </div>
      </div> -->
    </el-table>
    <!-- <sum ref="sum" :tab-column="sumColumn"></sum> -->
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :page-sizes="[50, 100, 150]"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <add ref="add" @refresh="fetchData"></add>
    <check ref="check"></check>
    <continue ref="continue" @refresh="fetchData"></continue>
    <review ref="review" @refresh="fetchData"></review>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="PD"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { getList, deleteRow } from '@/api/depotCheck'
  import { driverList, carSelectList } from '@/api/advanceOrder'
  import { getStaffList } from '@/api/setPrice'
  import Add from './components/add'
  import Continue from './components/continue'
  import Check from './components/check'
  import Review from './components/review'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  export default {
    name: 'Car',
    components: { Add, Continue, Check, Review, RuilangDisplayDialog },
    data() {
      return {
        loading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        data_id: null,
        time: [],
        carList: [],
        staffList: [],
        statusList: [
          {
            id: 1,
            name: '盘点中',
          },
          {
            id: 2,
            name: '未审核',
          },
          {
            id: 3,
            name: '已审核',
          },
          {
            id: 4,
            name: '已作废',
          },
        ],
        form: {
          depot_id: '', //:仓库id
          pageNo: 1, //:页数,默认第一页
          pageSize: 50, //:条数,默认10条
          start_time: '', //:开始时间
          end_time: '', //:结束时间
          status_type: '', //:状态1盘点中2未审核3已审核
          check_id: '', //:盘点人id
          is_close: '', //:0正常1已废弃
          type: '2', //:1仓 2车
        },
        checkList: [
          '盘点单号',
          '车辆',
          '归属人',
          '角色',
          '电话',
          '盘点人',
          '盘点时间',
          '状态',
        ],
        columns: [
          {
            order: 0,
            label: '盘点单号',
            prop: 'code',
            width: '',
          },
          {
            order: 1,
            label: '车辆',
            prop: 'depot_name',
            width: '',
          },
          {
            order: 2,
            label: '归属人',
            prop: 'driver_name',
            width: '',
          },
          {
            order: 3,
            label: '角色',
            prop: 'role',
            width: '',
          },
          {
            order: 4,
            label: '电话',
            prop: 'mobile',
            width: '',
          },
          {
            order: 5,
            label: '盘点人',
            prop: 'check_name',
            width: '',
          },
          {
            order: 5.5,
            label: '盘点时间',
            prop: 'create_at',
            width: '',
          },
          {
            order: 6,
            label: '状态',
            prop: 'status_txt',
            width: '',
          },
        ],
        sumColumn: [],
        list: [{}],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.fetchData()
      driverList().then((res) => {
        this.carList = res.data
      })
      getStaffList().then((res) => {
        this.staffList = res.data
      })
      carSelectList().then((res) => {
        this.carList = res.data
      })
    },
    mounted() {},
    activated() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true

        let { data, msg, code, totalCount } = await getList(this.form)
        console.log(data)
        this.list = data
        this.total = totalCount
        this.loading = false
      },
      // 新增对货
      handleAdd() {
        this.$refs.add.showDialog = true
      },
      handleQuery() {
        this.fetchData()
      },
      handleContinue(index, row) {
        this.$refs.continue.form.check_id = row.id
        this.$refs.continue.showDialog = true
      },
      handleReview(index, row) {
        this.$refs.review.form.check_id = row.id
        this.$refs.review.showDialog = true
        // review({ check_id: row.id }).then((res) => {
        //   console.log(res)
        //   this.$message.success(res.msg)
        //   this.fetchData()
        // })
      },
      handleView(index, row) {
        this.$refs.check.form.check_id = row.id
        this.$refs.check.status = row.status_txt
        this.$refs.check.showDialog = true
      },
      handleDelete(index, row) {
        this.$confirm('确定作废此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteRow({
              check_id: row.id,
            }).then((res) => {
              console.log(res)
              if (res.code == 200) {
                this.$message.success('作废成功')
                this.fetchData()
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      handleprint(index, row) {
        this.data_id = row.id
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      tabSum() {
        // 合计
        this.sumColumn = []
        this.columns.forEach((item, idx) => {
          if (idx == 1) {
            this.sumColumn.push({
              label: '123',
            })
          } else if (idx == 2) {
            this.sumColumn.push({
              label: '456',
            })
          } else {
            this.sumColumn.push({
              label: '',
            })
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .sum_footer {
    z-index: -1;
    display: flex;
    display: -webkit-flex;
    line-height: 50px;
    color: #606266;
  }
  .sum_footer_unit {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    font-size: 14px;
    text-indent: 10px;
  }
  .sum_footer_unit.center {
    text-align: center;
  }
  .sum_footer.xiaoji {
    border-bottom: 1px solid #ebeef5;
  }
</style>
